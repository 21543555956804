@import "partials/config";
@import "partials/helpers";
@import "partials/icon-fonts";

$c-cta-color: #ff8e00 !default;
$c-cta-color--font: #ffffff !default;
$c-cta-size: 28px !default;

$c-cta-spacing: 0.5em;

.cta {
  position: absolute;
  right: 1em;
  top: 1em;

  .btn {
    border-radius: $c-cta-size;
    background: $c-cta-color;
    min-height: $c-cta-size;
    min-width: $c-cta-size;
    display: inline-block;
    padding: #{ $c-cta-size * 0.3 };
    opacity: 1;
    transition: opacity 0.5s;

    &:hover {
      opacity: 0.75;
    }

    &:not(:first-child) {
      margin-left: $c-cta-spacing;
    }

    i,
    .icon,
    .text {
      color: $c-cta-color--font;
      fill: $c-cta-color--font;
      float: left;
      line-height: $c-cta-size;
    }

    i {
      font-size: 1.5em;
    }

    .text {
      text-transform: uppercase;
      margin-top: 1px;
      margin-bottom: -1px;

      @include media(sm) {
        display: none;
      }
    }

    @include media(sm, $minmax: min) {
      & > *:not(:only-child) {
        margin-left: 0.2em;

        &:last-child {
          margin-right: 0.2em;
        }
      }
    }
  }
}
