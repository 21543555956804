@import "./config";

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.clearfix {
  @include clearfix;
}

@mixin media($key, $minmax: max, $media: all) {
  $bp-value: map_get($breakpoints, $key);
  @if $minmax == min {
    $bp-value: $bp-value + 1;
  }
  @media #{$media} and (#{$minmax}-width: $bp-value) {
    @content;
  }
}

@mixin transition($prop: all, $duration: 0.5s, $effect:unquote('')) {
  transition: $prop $duration $effect;
}