@import "partials/corporate";
@import "./partials/config";
@import "./partials/helpers";

@import "./partials/components/cta-buttons";

.page {
  background-image: url("/background_recoustic.jpg");
  background-color: rgba(255, 142, 0, 0.2);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
}

.wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

h1.name {
  color: $color-1;
  font-weight: 300;
  font-size: 15vw;
  margin: 0;
}

.title {
  margin: 0;
  font-size: 80px;
  text-transform: uppercase;
  color: #dedede;

  @include media(md) {
    font-size: 60px;
  }

  @include media(sm) {
    font-size: 45px;
  }

  @include media(xs) {
    font-size: 38px;
  }
}