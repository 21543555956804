$icomoon-font-path: "/assets/static/fonts" !default;

$icon-phone: "\e0cd";
$icon-navigate_before: "\e408";
$icon-navigate_next: "\e409";
$icon-mail_outline: "\e0e1";
$icon-mail: "\e159";
$icon-menu: "\e5d2";

@font-face {
  font-family: 'Base-Icons';
  src:  url('#{$icomoon-font-path}/Base-Icons.eot?5h0kms');
  src:  url('#{$icomoon-font-path}/Base-Icons.eot?5h0kms#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/Base-Icons.ttf?5h0kms') format('truetype'),
  url('#{$icomoon-font-path}/Base-Icons.woff?5h0kms') format('woff'),
  url('#{$icomoon-font-path}/Base-Icons.svg?5h0kms#Base-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Base-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-navigate_before {
  &:before {
    content: $icon-navigate_before;
  }
}
.icon-navigate_next {
  &:before {
    content: $icon-navigate_next;
  }
}
.icon-mail_outline {
  &:before {
    content: $icon-mail_outline;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
